<template>
  <form
    @submit="submitEnquiry"
    autocomplete="off"
    class="w-full max-h-screen bg-background flex flex-col"
  >
    <page-header title="Create Sales Enquiry">
      <button
        type="submit"
        class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </page-header>

    <main
      class="p-6 bg-background flex-1 relative"
      :class="{ 'overflow-y-hidden': isBusy, 'overflow-y-scroll': !isBusy }"
    >
      <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
      >
        <spinner :color="'text-black'" :size="10" />
      </div>
      <div>
        <div class="masonry sm:masonry-2-col">
          <div
            class="masonry-item bg-white rounded shadow-md flex items-center"
          >
            <div class="masonry-content p-5 relative w-full">
              <div class="flex flex-col">
                <div class="flex items-center mb-5">
                  <h3
                    class="uppercase text-lg font-semibold text-primary tracking-wide"
                  >
                    Company Details
                  </h3>
                  <hr class="flex-1 border-primary ml-4" />
                </div>
                <div class="flex flex-wrap -my-2 -mx-5">
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm">
                      Company / Individual Name
                      <span class="text-red-500">*</span>
                    </label>
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="prospect.name"
                      required
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Postcode</label
                    >
                    <div class="flex w-full relative">
                      <input
                        class="bge-input bge-input-spacing rounded flex-1"
                        type="text"
                        v-model="prospect.postcode"
                      />
                      <button
                        type="button"
                        @click="findAddress"
                        class="relative overflow-hidden ml-5 flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-3 py-1"
                      >
                        <svg
                          class="stroke-current h-5 w-5 mr-2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"
                          />
                          <circle cx="12" cy="10" r="3" />
                        </svg>
                        <span>Find Address</span>
                        <div
                          v-if="selectAddressState == 'Loading'"
                          class="z-10 bg-green-200 absolute inset-0 flex items-center justify-center"
                        >
                          <spinner :color="'text-secondary'" :size="5" />
                        </div>
                      </button>
                      <div
                        v-if="selectAddressState == 'Results'"
                        class="mt-5 pl-5 absolute bg-white shadow-2xl rounded-lg z-20 p-4 flex flex-col"
                      >
                        <label class="uppercase font-semibold text-sm"
                          >Select Address</label
                        >
                        <div class="inline-block relative">
                          <select
                            v-model="selectedAddress"
                            class="bge-input bge-select rounded"
                          >
                            <template
                              v-for="(address,
                              i) in availableAddressData.addresses"
                            >
                              <option :key="i" :value="address">{{
                                address.formatted_address
                              }}</option>
                            </template>
                          </select>
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                          >
                            <svg
                              class="fill-current h-4 w-4"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="text-right">
                          <button
                            @click="selectAddress"
                            type="button"
                            class="mt-3 inline-flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-3 py-1"
                          >
                            <svg
                              class="stroke-current h-5 w-5 mr-2"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"
                              />
                              <circle cx="12" cy="10" r="3" />
                            </svg>
                            <span>Select Address</span>
                          </button>
                        </div>
                      </div>
                      <div
                        class="fixed inset-0 z-10"
                        @click="selectAddressState = 'Idle'"
                        v-if="selectAddressState == 'Results'"
                      ></div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Address 1</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="prospect.address1"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Address 2</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="prospect.address2"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Address 3</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="prospect.address3"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm">City</label>
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="prospect.city"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >County</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="prospect.county"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Country</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="prospect.country"
                        class="bge-input bge-select rounded"
                      >
                        <optgroup label="Frequently Used">
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Kenya">Kenya</option>
                          <option value="England">England</option>
                          <option value="Scotland">Scotland</option>
                          <option value="Wales">Wales</option>
                          <option value="Northern Ireland"
                            >Northern Ireland</option
                          >
                          <option value="Republic of Ireland"
                            >Republic of Ireland</option
                          >
                        </optgroup>
                        <optgroup label="All Countries">
                          <template v-for="(country, i) in countries">
                            <option :key="i" :value="country">{{
                              country
                            }}</option>
                          </template>
                        </optgroup>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Website</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="prospect.website"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="masonry-item bg-white rounded shadow-md flex items-center"
          >
            <div class="masonry-content p-5 relative w-full">
              <div class="flex flex-col">
                <div class="flex items-center mb-5">
                  <h3
                    class="uppercase text-lg font-semibold text-primary tracking-wide"
                  >
                    Enquiry Status
                  </h3>
                  <hr class="flex-1 border-primary ml-4" />
                </div>
                <div class="flex flex-wrap -my-2 -mx-5">
                  <div class="flex flex-col w-full md:w-1/3 my-2 pl-5 pr-2">
                    <label class="uppercase font-semibold text-sm"
                      >Enquiry Status</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="prospect.status"
                        class="bge-input bge-select rounded"
                      >
                        <option value="Open">Open</option>
                        <option value="Closed (Sold)">Closed (Sold)</option>
                        <option value="Closed (Lost)">Closed (Lost)</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full md:w-2/3 my-2 pl-2 pr-5">
                    <label class="uppercase font-semibold text-sm"
                      >Enquiry Status Detail</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="prospect.status_detail"
                        class="bge-input bge-select rounded"
                      >
                        <template v-for="option in selectedDetailOptions">
                          <option :key="option" :value="option">{{
                            option
                          }}</option>
                        </template>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="masonry-item bg-white rounded shadow-md flex items-center"
          >
            <div class="masonry-content p-5 relative w-full">
              <div class="flex flex-col">
                <div class="flex items-center mb-5">
                  <h3
                    class="uppercase text-lg font-semibold text-primary tracking-wide"
                  >
                    Primary Contact
                  </h3>
                  <hr class="flex-1 border-primary ml-4" />
                </div>
                <div class="flex flex-wrap -my-2 -mx-5">
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Job Title</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="primary_contact.job_title"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >First Name</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="primary_contact.firstname"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Last Name</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="primary_contact.lastname"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm">Email</label>
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="email"
                      v-model="primary_contact.email"
                    />
                  </div>

                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Telephone</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="primary_contact.telephone"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Mobile</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="primary_contact.mobile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="masonry-item bg-white rounded shadow-md flex items-center"
          >
            <div class="masonry-content p-5 relative w-full">
              <div class="flex flex-col">
                <div class="flex items-center mb-5">
                  <h3
                    class="uppercase text-lg font-semibold text-primary tracking-wide"
                  >
                    Marketing Details
                  </h3>
                  <hr class="flex-1 border-primary ml-4" />
                </div>
                <div class="flex flex-wrap -my-2 -mx-5">
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Contact Date</label
                    >
                    <datepicker
                      input-class="bge-input bge-input-spacing rounded w-full"
                      v-model="prospect.enquiry_date"
                      format="dd/MM/yyyy"
                    ></datepicker>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Quoted Price</label
                    >
                    <div
                      class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                    >
                      <div class="flex justify-center items-center p-2">
                        <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                          <path
                            d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                          />
                        </svg>
                      </div>
                      <input
                        v-model="prospect.price_quoted"
                        class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Contact Method</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="prospect.enquiry_method"
                        class="bge-input bge-select rounded"
                      >
                        <option value="Sales Enquiry Form"
                          >Sales Enquiry Form</option
                        >
                        <option value="Sales Callback Form"
                          >Sales Callback Form</option
                        >
                        <option value="Direct Email">Direct Email</option>
                        <option value="Direct Phone Call (Office)"
                          >Direct Phone Call (Office)</option
                        >
                        <option value="Direct Phone Call (Ross Mobile)"
                          >Direct Phone Call (Ross Mobile)</option
                        >
                        <option value="Walk-In">Walk-In</option>
                        <option value="Trade Show">Trade Show</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Twitter">Twitter</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Other">Other</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Marketing Source</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="prospect.enquiry_source"
                        class="bge-input bge-select rounded"
                      >
                        <option value="Unknown">Unknown</option>
                        <option value="Existing client">Existing client</option>
                        <option value="Internet search">Internet search</option>
                        <option value="Google Search">Google Search</option>
                        <option value="Bing Search">Bing Search</option>
                        <option value="Other Search Engine"
                          >Other Search Engine</option
                        >
                        <option value="Google Adwords">Google Adwords</option>
                        <option value="Magazine">Magazine</option>
                        <option value="Agent referral">Agent referral</option>
                        <option value="Customer referral"
                          >Customer referral</option
                        >
                        <option value="Aware of Boston brand"
                          >Aware of Boston brand</option
                        >
                        <option value="Test Centre referral"
                          >Test Centre referral</option
                        >
                        <option value="Seen in a garage"
                          >Seen in a garage</option
                        >
                        <option value="Previously met client"
                          >Previously met client</option
                        >
                        <option value="Previously quoted"
                          >Previously quoted</option
                        >
                        <option value="Portman">Portman</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Twitter">Twitter</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Other">Other</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Product Enquiry</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="enquiredProduct"
                        class="bge-input bge-select rounded"
                      >
                        <option value="1-Post Lift">1-Post Lift</option>
                        <option value="2-Post Lift">2-Post Lift</option>
                        <option value="4-Post Lift">4-Post Lift</option>
                        <option value="Scissor Lift">Scissor Lift</option>
                        <option value="Class 1 &amp; 2 MOT Bay">Class 1 &amp; 2 MOT Bay</option>
                        <option value="Class 4 MOT Bay">Class 4 MOT Bay</option>
                        <option value="Class 7 MOT Bay">Class 7 MOT Bay</option>
                        <option value="C1 &amp; 2 Brake Tester">C1 &amp; 2 Brake Tester</option>
                        <option value="C4 Brake Tester">C4 Brake Tester</option>
                        <option value="C7 Brake Tester">C7 Brake Tester</option>
                        <option value="Decelerometer">Decelerometer</option>
                        <option value="Emissions Equipment"
                          >Emissions Equipment</option
                        >
                        <option value="Headlamp Aim Tester"
                          >Headlamp Aim Tester</option
                        >
                        <option value="Play Detector">Play Detector</option>
                        <option value="Tyre Changer">Tyre Changer</option>
                        <option value="Wheel Balancer">Wheel Balancer</option>
                        <option value="Jacking">Jacking</option>
                        <option value="Service &amp; Maintenance"
                          >Service &amp; Maintenance</option
                        >
                        <option value="OPTL Upgrade">OPTL Upgrade</option>
                        <option value="GarageWorx">GarageWorx</option>
                        <option value="O3-NEX">O3-NEX</option>
                        <option value="AC Hydraulic">AC Hydraulic</option>
                        <option value="Turning Plates">Turning Plates</option>
                        <option value="Jacking Beam">Jacking Beam</option>
                        <option value="Connected Equipment"
                          >Connected Equipment</option
                        >
                        <option value="MCU Upgrade">MCU Upgrade</option>
                        <option value="Unknown">Unknown</option>
                        <option value="Other - see Notes"
                          >Other - See Notes</option
                        >
                        <option value="Other">Other</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Default Agent</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="prospect.default_agent.name"
                        class="bge-input bge-select rounded"
                      >
                        <option value="Advance GE">Advance GE</option>
                        <option value="Ashford Garage Equipment"
                          >Ashford Garage Equipment</option
                        >
                        <option value="Autoquip">Autoquip</option>
                        <option value="Boston">Boston</option>
                        <option value="Boston - KENYA">Boston - KENYA</option>
                        <option value="Brain Bee">Brain Bee</option>
                        <option value="C &amp; V Lift Services"
                          >C &amp; V Lift Services</option
                        >
                        <option value="C-Tech">C-Tech</option>
                        <option value="CARS">CARS</option>
                        <option value="D&amp;H Equipment Services"
                          >D&amp;H Equipment Services</option
                        >
                        <option value="Down GE">Down GE</option>
                        <option value="Euro Tec Equipment"
                          >Euro Tec Equipment</option
                        >
                        <option value="First Equipment">First Equipment</option>
                        <option value="Garage Equip Nationwide"
                          >Garage Equip Nationwide</option
                        >
                        <option value="GEUK Ltd">GEUK Ltd</option>
                        <option value="Hilliers GE">Hilliers GE</option>
                        <option value="Hitech GE">Hitech GE</option>
                        <option value="KRB">KRB</option>
                        <option value="KSA">KSA</option>
                        <option value="MAHA UK Ltd">MAHA UK Ltd</option>
                        <option value="R A Cox GE">R A Cox GE</option>
                        <option value="S &amp; G Precision Engineering"
                          >S &amp; G Precision Engineering</option
                        >
                        <option value="Saracen Distribution Ltd"
                          >Saracen Distribution Ltd</option
                        >
                        <option value="SMG Garage Equipment"
                          >SMG Garage Equipment</option
                        >
                        <option value="SWGE">SWGE</option>
                        <option value="Transtec GE">Transtec GE</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="masonry-item bg-white rounded shadow-md flex items-center"
          >
            <div class="masonry-content p-5 relative w-full">
              <div class="flex flex-col">
                <div class="flex items-center mb-5">
                  <h3
                    class="uppercase text-lg font-semibold text-primary tracking-wide"
                  >
                    Secondary Contact Details
                  </h3>
                  <hr class="flex-1 border-primary ml-4" />
                </div>
                <div class="flex flex-wrap -my-2 -mx-5">
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Job Title</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="secondary_contact.job_title"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Firstname</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="secondary_contact.firstname"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Lastname</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="secondary_contact.lastname"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm">Email</label>
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="email"
                      v-model="secondary_contact.email"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Telephone</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="secondary_contact.telephone"
                    />
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Mobile</label
                    >
                    <input
                      class="bge-input bge-input-spacing rounded"
                      type="text"
                      v-model="secondary_contact.mobile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="masonry-item bg-white rounded shadow-md flex items-center"
          >
            <div class="masonry-content p-5 relative w-full">
              <div class="flex flex-col">
                <div class="flex items-center mb-5">
                  <h3
                    class="uppercase text-lg font-semibold text-primary tracking-wide"
                  >
                    Description / Notes
                  </h3>
                  <hr class="flex-1 border-primary ml-4" />
                </div>
                <div class="flex flex-wrap -my-2 -mx-5">
                  <div class="w-full my-2 px-5">
                    <textarea
                      v-model="prospect.notes"
                      class="bge-input bge-input-spacing rounded resize-none w-full"
                      rows="6"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="masonry-item bg-white rounded shadow-md flex items-center"
          >
            <div class="masonry-content p-5 relative w-full">
              <div class="flex flex-col">
                <div class="flex items-center mb-5">
                  <h3
                    class="uppercase text-lg font-semibold text-primary tracking-wide"
                  >
                    Schedule Follow Up Action
                  </h3>
                  <hr class="flex-1 border-primary ml-4" />
                </div>
                <div class="flex flex-wrap -my-2 -mx-5">
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Follow Up Date</label
                    >
                    <div class="flex flex-col sm:flex-row w-full">
                      <div class="flex-1">
                        <datepicker
                          input-class="bge-input bge-input-spacing rounded w-full"
                          v-model="followUpAppointment.date"
                          :disabled-dates="disabledDates"
                          placeholder="Select follow up date"
                          format="dd/MM/yyyy"
                        ></datepicker>
                      </div>
                      <div class="flex pt-2 sm:pt-0 -mx-1 ml-1">
                        <div class="inline-block relative mx-1">
                          <select
                            v-model="followUpAppointment.hour"
                            class="bge-input bge-select rounded"
                          >
                            <template v-for="(option, i) in hourOptions">
                              <option :key="i" :value="option.value">{{
                                option.text
                              }}</option>
                            </template>
                          </select>
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                          >
                            <svg
                              class="fill-current h-4 w-4"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="inline-block relative mx-1">
                          <select
                            v-model="followUpAppointment.minute"
                            class="bge-input bge-select rounded"
                          >
                            <template v-for="(option, i) in minuteOptions">
                              <option :key="i" :value="option.value">{{
                                option.text
                              }}</option>
                            </template>
                          </select>
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                          >
                            <svg
                              class="fill-current h-4 w-4"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-2 px-5">
                    <label class="uppercase font-semibold text-sm"
                      >Assign Follow Up Action To</label
                    >
                    <div class="inline-block relative">
                      <select
                        v-model="followUpAppointment.userId"
                        class="bge-input bge-select rounded"
                      >
                        <template
                          v-for="(user, i) in followUpAppointment.userOptions"
                        >
                          <option :key="i" :value="user.value">{{
                            user.text
                          }}</option>
                        </template>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
const PageHeader = () => import("@/components/PageHeader.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
import { COUNTRIES } from "../../utils/constants";

export default {
  name: "CreateProspect",
  components: {
    PageHeader,
    Spinner,
    Notification,
    Datepicker,
  },
  data() {
    return {
      isBusy: true,
      enquiredProduct: null,
      selectAddressState: "Idle",
      availableAddressData: [],
      selectedAddress: "",
      disabledDates: {
        to: new Date(),
      },
      followUpAppointment: {
        date: new Date(),
        hour: 9,
        minute: 0,
        userId: null,
        userOptions: [{ value: null, text: "Loading users..." }],
      },
      availableUsers: [],
      primary_contact: {},
      secondary_contact: {},
      prospect: {
        enquiry_date: new Date(),
        country: "United Kingdom",
        enquired_products: [],
        events: [],
        contacts: [],
        default_agent: { name: "Boston" },
      },
      hourOptions: [],
      minuteOptions: [],
      countries: [],
      selectedDetailOptions: "",
      openStatusDetailOptions: [
        "New",
        "No Successful Contact As Yet",
        "Sent to Agent",
        "Contacted By Boston/Agent - Awaiting Quote",
        "Contacted By Agent - Awaiting Site Visit",
        "Researching Only - Early Stages",
        "Looking For Premises",
        "Awaiting planning Permission for new building / Extension",
        "Premises Currently Being Built / Extended/Altered",
        "Site Inspection booked",
        "Sent Quote",
        "Received Quote Considering",
        "Awaiting Council Approval",
        "Awaiting CAD Plans",
        "Awaiting AIP from DVSA",
        "Awaiting Finance / Kennetts",
        "Finance Agreed Proceeding",
        "Has AIP - Awaiting Ground Works / Install",
        "Considering But Doesn’t Want Chasing",
        "Other See Notes",
      ],
      soldStatusDetailOptions: ["Boston Equipment Sold"],
      lostStatusDetailOptions: [
        "Boston Too Expensive - Bought / Buying Other",
        "Preferred Competitors Equipment - Bought / Buying Other",
        "Slow Response From or Won't Deal With Local Agent",
        "Enquiry For Unsupported Product",
        "Customer Wants / Bought 2nd Hand Equipment",
        "Premises Do Not Meet DVSA Requirements",
        "Our Lift Too Big, Buying Smaller",
        "Repaired Faulty Equipment As Cheaper",
        "Situation Changed - No Longer Interested",
        "Unable To Contact / No Reply From Customer",
        "Unable To Afford / Finance Declined",
        "Other See Notes",
        "Not Viable",
        "Unknown",
      ],
    };
  },
  watch: {
    "prospect.status": function() {
      switch (this.prospect.status) {
        case "Closed (Sold)":
          this.selectedDetailOptions = this.soldStatusDetailOptions;
          break;
        case "Closed (Lost)":
          this.selectedDetailOptions = this.lostStatusDetailOptions;
          break;
        default:
          this.selectedDetailOptions = this.openStatusDetailOptions;
          break;
      }
    },
  },
  async mounted() {
    for (var hr = 0; hr < 24; hr++) {
      this.hourOptions.push({
        value: hr,
        text: `${String(hr).padStart(2, "0")}`,
      });
    }

    for (var min = 0; min < 60; min += 5) {
      this.minuteOptions.push({
        value: min,
        text: `${String(min).padStart(2, "0")}`,
      });
    }

    this.countries = COUNTRIES;

    try {
      this.availableUsers = await this.UserService.getUsers();
      let userOptions = this.availableUsers.map((usr) => {
        return {
          value: usr.user_id,
          text: usr.name,
        };
      });
      this.followUpAppointment.userOptions = [
        { value: null, text: "Assign to user (optional)" },
        ...userOptions,
      ];
    } catch (err) {
      console.error("Error while getting users", err);
    }

    window.addEventListener("resize", () => {
      this.resizeAllMasonryItems();
    });

    this.resizeAllMasonryItems();

    this.isBusy = false;
  },
  methods: {
    async submitEnquiry(evt) {
      evt.preventDefault();
      this.isBusy = true;

      try {
        let primaryContact = this.primary_contact;
        if (
          !primaryContact.email &&
          !primaryContact.telephone &&
          !primaryContact.mobile
        ) {
          this.isBusy = false;
          return this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "warning",
                    title: "Missing Fields",
                    close: onClose,
                  },
                },
                "Please add either an email, mobile or telephone for primary contact"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        this.prospect.contacts = [];

        // Create the contacts list
        this.prospect.contacts.push({
          job_title: this.primary_contact.job_title,
          firstname: this.primary_contact.firstname,
          lastname: this.primary_contact.lastname,
          salutation: this.primary_contact.salutation,
          email: this.primary_contact.email,
          telephone: this.primary_contact.telephone,
          mobile: this.primary_contact.mobile,
          is_primary: true,
        });

        let secondContact = this.secondary_contact;
        if (
          secondContact.firstname ||
          secondContact.lastname ||
          secondContact.email ||
          secondContact.telephone ||
          secondContact.mobile
        ) {
          // Ensure secondary contact has mandatory fields filled out
          if (
            !secondContact.email &&
            !secondContact.telephone &&
            !secondContact.mobile
          ) {
            this.isBusy = false;
            return this.$breadstick.notify(
              ({ h, onClose }) => {
                return h(
                  Notification,
                  {
                    props: {
                      type: "warning",
                      title: "Missing Fields",
                      close: onClose,
                    },
                  },
                  "Please add either an email, mobile or telephone for secondary contact"
                );
              },
              {
                position: "top-right",
              }
            );
          }

          this.prospect.contacts.push({
            job_title: secondContact.job_title,
            firstname: secondContact.firstname,
            lastname: secondContact.lastname,
            salutation: secondContact.salutation,
            email: secondContact.email,
            telephone: secondContact.telephone,
            is_primary: false,
          });
        }

        // Populate the enquired products list
        if (this.enquiredProduct) {
          this.prospect.enquired_products.push({
            name: this.enquiredProduct,
          });
        }

        if (!this.prospect.status || !this.prospect.status_detail) {
          this.isBusy = false;
          return this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "warning",
                    title: "Missing Fields",
                    close: onClose,
                  },
                },
                "Please add an enquiry status and status detail"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        // Populate the follow up event
        if (this.followUpAppointment.date) {
          let dtBase = this.$moment(this.followUpAppointment.date);
          let followUpDate = this.$moment([
            dtBase.year(),
            dtBase.month(),
            dtBase.date(),
          ])
            .add(this.followUpAppointment.hour, "hours")
            .add(this.followUpAppointment.minute, "minutes");

          let followUpEventObj = {
            type: "Follow Up",
            date: followUpDate.format(),
            notify: true,
            name: `Follow up call with ${this.prospect.name}`,
            assigned_to: [],
          };

          // Assign a user if specified
          let assignedUser = this.availableUsers.find((u) => {
            return u.user_id == this.followUpAppointment.userId;
          });

          if (assignedUser) {
            followUpEventObj.assigned_to.push(assignedUser);
          }

          this.prospect.events.push(followUpEventObj);
        }

        let createResult = await this.SalesProspectService.createProspect(
          this.prospect
        );
        this.$router.push({
          name: "prospect-edit",
          params: {
            prospectId: createResult.prospect_id,
            isCreateResult: true,
          },
        });
      } catch (err) {
        console.error(err);
        let message =
          err.status == 400
            ? "Unable to create new enquiry. Please check all required fields are filled out"
            : "Unable to create new enquiry. Please try again later";

        this.isBusy = false;

        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              message
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    findAddress: async function() {
      try {
        if (this.prospect.postcode) {
          this.selectAddressState = "Loading";
          let result = await this.SalesProspectService.findAddressFromPostcode(
            this.prospect.postcode
          );
          this.availableAddressData = result.data;
          this.selectAddressState = "Results";
        }
      } catch (error) {
        console.error(error);
        this.selectAddressState = "Idle";
        let errorText;
        switch (error.status) {
          case 404:
            errorText = "No addresses could be found for this postcode.";
            break;
          case 400:
            errorText = "Postcode is invalid";
            break;
          case 429:
            errorText = "You have made more requests than your allowed limit.";
            break;
          default:
            errorText = "There has been an error.";
            break;
        }
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: `${error.status}: ${error.data}`,
                  close: onClose,
                },
              },
              errorText
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    selectAddress: function() {
      if (this.selectedAddress) {
        this.selectAddressState = "Idle";
        this.prospect.postcode = this.availableAddressData.postcode;
        this.prospect.address1 = this.selectedAddress.line_1;
        this.prospect.address2 = this.selectedAddress.line_2;
        this.prospect.address3 = this.selectedAddress.line_3;
        this.prospect.city = this.selectedAddress.town_or_city;
        this.prospect.county = this.selectedAddress.county;
        this.prospect.country = this.selectedAddress.country;
      }
    },
    resizeMasonryItem: function(item) {
      var grid = document.getElementsByClassName("masonry")[0];
      if (grid) {
        var rowGap = parseInt(
          window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
        );
        var rowHeight = parseInt(
          window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
        );
        var rowSpan = Math.ceil(
          (item.querySelector(".masonry-content").getBoundingClientRect()
            .height +
            rowGap) /
            (rowHeight + rowGap)
        );
        item.style.gridRowEnd = "span " + rowSpan;
      }
    },
    resizeAllMasonryItems: function() {
      var allItems = document.querySelectorAll(".masonry-item");
      allItems.forEach((item) => {
        this.resizeMasonryItem(item);
      });
    },
  },
};
</script>
